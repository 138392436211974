import { useState } from "react";
import { cn } from "@/utils/helpers";
import { motion } from "framer-motion";

interface Feature {
  id: number;
  title: string;
  description: string;
  image: string;
}

export const defaultMenuFeatures: Feature[] = [
  {
    id: 1,
    title: "Receipts",
    description:
      "Process receipts and invoices with our advanced OCR to extract totals, dates, items and more",
    image: "/images/features/reecipt.webp",
  },
  {
    id: 2,
    title: "Checks",
    description:
      "Digitize checks with high accuracy using our state-of-the-art OCR technology and AI-powered recognition.",
    image: "/images/features/check.webp",
  },
  {
    id: 3,
    title: "Business Cards",
    description:
      "Extract contact information from business cards instantly with our optimized recognition system.",
    image: "/images/features/bc.webp",
  },
  {
    id: 4,
    title: "ID Documents",
    description:
      "Automatically detect and extract information from various ID documents while maintaining data security.",
    image: "/images/features/id.webp",
  },
  {
    id: 5,
    title: "Credit Cards",
    description:
      "Securely process and extract credit card information with our PCI-compliant OCR capabilities.",
    image: "/images/features/card.webp",
  },
  {
    id: 6,
    title: "Handwritten Text",
    description:
      "Advanced algorithms analyze handwritten text with precision, ensuring accurate extraction from complex documents.",
    image: "/images/features/hand.webp",
  },
];

export const defaultGridFeatures: Feature[] = [
  {
    id: 1,
    title: "Document Analysis",
    description: `Advanced algorithms analyze document structure and layout with precision, ensuring accurate text extraction from complex documents.`,
    image: "",
  },
  {
    id: 2,
    title: "Multi-Language Support",
    description:
      "Process documents in multiple languages with our advanced OCR engine that supports various scripts and character sets.",
    image: "",
  },
  {
    id: 3,
    title: "High Accuracy",
    description:
      "Achieve high accuracy in text extraction with our state-of-the-art OCR technology and AI-powered recognition.",
    image: "",
  },
  {
    id: 4,
    title: "Real-time Processing",
    description:
      "Experience lightning-fast document processing with our optimized cloud infrastructure.",
    image: "",
  },
  {
    id: 5,
    title: "Folder Management",
    description:
      "Folder management capabilities allow you to organize and store documents efficiently.",
    image: "",
  },
  {
    id: 6,
    title: "Batch Processing",
    description:
      "Process multiple documents simultaneously with our efficient batch processing capabilities.",
    image: "",
  },
];

export interface FeaturesSectionProps {
  title?: string;
  subtitle?: string;
  menuFeatures?: Feature[];
  gridFeatures?: Feature[] | null;
}

export function FeaturesSection({
  menuFeatures = defaultMenuFeatures,
  gridFeatures = defaultGridFeatures,
}: FeaturesSectionProps) {
  const [selectedFeature, setSelectedFeature] = useState<Feature>(
    menuFeatures[0]
  );

  return (
    <div className="relative lg-black">
      <div className="relative z-20 max-w-7xl mt-6 mx-auto">
        {/* Features Grid */}
        {gridFeatures && gridFeatures.length > 0 && (
          <div className="mt-12 px-8">
            <h2 className="text-center text-3xl md:text-4xl mt-6 font-light text-white mb-8">
              Features
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {gridFeatures.map((feature) => (
                <motion.div
                  key={feature.id}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.1 * feature.id }}
                  className="backdrop-blur-sm border hover:bg-white/5 border-neutral-800 rounded-lg p-6 relative"
                >
                  <h3 className="text-2xl font-light text-white capitalize mb-2">
                    {feature.title}
                  </h3>
                  <pre className="text-neutral-300 text-sm mb-4 whitespace-pre-wrap font-sans leading-normal">
                    {feature.description}
                  </pre>
                  {/* Bottom gradient */}
                  <div className="absolute bottom-0 right-4 mt-[2px] flex h-8 items-end overflow-hidden">
                    <div className="flex -mb-px h-[2px] w-80 -scale-x-100">
                      <div className="w-full flex-none blur-sm [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                      <div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        )}

        {/* Feature Details Section */}
        <div className="mt-20 px-8">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl md:text-4xl font-light text-center text-white mb-12"
          ></motion.h2>
        </div>

        {/* Features Menu */}
        <div className="flex flex-col mt-6 lg:flex-row gap-8 px-8">
          {/* Side Menu */}
          <div className="w-full lg:w-1/3">
            <div className="space-y-4">
              {menuFeatures.map((feature) => (
                <motion.div
                  key={feature.id}
                  className="relative"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.1 * feature.id }}
                >
                  <button
                    onClick={() => setSelectedFeature(feature)}
                    className={cn(
                      "w-full text-left p-4 rounded-lg transition-all duration-200",
                      selectedFeature.id === feature.id
                        ? "bg-blue-500/10 border border-blue-500/20"
                        : "hover:bg-neutral-800/50 border border-neutral-800"
                    )}
                  >
                    <h3 className="text-2xl font-light text-white capitalize mb-2">
                      {feature.title}
                    </h3>
                    <pre className="text-neutral-400 text-sm line-clamp-2 font-sans">
                      {feature.description}
                    </pre>
                  </button>
                  {/* Bottom gradient */}
                  <div className="absolute bottom-0 right-4 mt-[2px] flex h-8 items-end overflow-hidden">
                    <div className="flex -mb-px h-[2px] w-80 -scale-x-100">
                      <div className="w-full flex-none blur-sm [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                      <div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Feature Display */}
          <div className="w-full lg:w-2/3">
            <motion.div
              key={selectedFeature.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="backdrop-blur-sm border border-neutral-800 rounded-lg p-6"
            >
              <div className="w-full overflow-hidden rounded-lg mb-6 relative">
                <img
                  src={selectedFeature.image}
                  alt={selectedFeature.title}
                  className="w-full h-auto"
                />
              </div>
              <h2 className="text-2xl font-medium text-white mb-4">
                {selectedFeature.title}
              </h2>
              <pre className="text-neutral-300 leading-relaxed whitespace-pre-wrap font-sans">
                {selectedFeature.description}
              </pre>
              {/* Bottom gradient */}
              <div className="absolute bottom-0 right-4 mt-[2px] flex h-8 items-end overflow-hidden">
                <div className="flex -mb-px h-[2px] w-80 -scale-x-100">
                  <div className="w-full flex-none blur-sm [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                  <div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
