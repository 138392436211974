import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Landing } from "./pages/Landing";
import "./styles/globals.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Landing />
  </React.StrictMode>
);
