import { motion } from "framer-motion";
import { IconCheck } from "@tabler/icons-react";
import { type Plan } from "@/types";
import { PLANS } from "@/constants/plans";
import { cn } from "@/utils/helpers";
import { Button } from "./Button";
import { ROUTES } from "@/constants/routes";

export function PricingSection() {
  const handleGetStarted = (plan: Plan) => {
    if (plan.isCustom) {
      document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.location.href = `${ROUTES.APP_SIGNUP}?plan=${plan.name}`;
    }
  };

  return (
    <section id="pricing" className="py-16 px-4 relative">
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="text-center mb-6"
        >
          <h2 className="text-3xl md:text-4xl font-light text-white mb-8">
            Simple, Transparent Pricing
          </h2>
          <p className="text-neutral-400 max-w-2xl mx-auto mb-8">
            Choose the perfect plan for your needs. All plans include our core OCR
            features with varying levels of power and flexibility.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {PLANS.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
            >
              <div className={cn(
                "relative rounded-2xl backdrop-blur-sm hover:bg-white/5 border border-white/10",
                "p-8 h-full flex flex-col",
                plan.name === "pro" && "border-blue-500/50 shadow-lg shadow-blue-500/20"
              )}>
                {plan.name === "pro" && (
                  <div className="absolute -top-4 left-0 right-0 flex justify-center">
                    <div className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                      Most Popular
                    </div>
                  </div>
                )}

                <div>
                  <h3 className="text-2xl font-light text-white capitalize mb-2">
                    {plan.name}
                  </h3>
                  <div className="flex items-baseline mb-6">
                    {typeof plan.price === 'number' ? (
                      <>
                        <span className="text-4xl font-light text-white">${plan.price}</span>
                        <span className="text-neutral-400 ml-2">/month</span>
                      </>
                    ) : (
                      <span className="text-4xl font-light text-white">{plan.price}</span>
                    )}
                  </div>
                </div>

                <ul className="space-y-4 mb-8 flex-grow">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center text-neutral-300">
                      <IconCheck className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <Button
                  onClick={() => handleGetStarted(plan)}
                  className={cn(
                    "backdrop-blur-sm mx-auto w-fit",
                    plan.isCustom
                      ? "!bg-blue-500/20 hover:!bg-blue-500/30"
                      : "!bg-white/10 hover:!bg-white/20"
                  )}
                  withArrow={true}
                >
                  {plan.isCustom ? 'Contact Us' : 'Get Started'}
                </Button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}