import { motion } from "framer-motion";
import { FloatingNav } from "@/components/ui/FloatingNav";
import { Beam } from "@/components/ui/Beam";
import { Button } from "@/components/ui/Button";
import { Logo } from "@/components/ui/Logo";
import { PricingSection } from "@/components/ui/PricingSection";
import { ContactSection } from "@/components/ui/ContactSection";
import { FeaturesSection } from "@/components/ui/Features";
import { useEffect } from "react";
import { ROUTES } from "@/constants/routes";

const navItems = [
  {
    name: "Home",
    link: "#home",
  },
  {
    name: "Features",
    link: "#features",
  },
  {
    name: "Pricing",
    link: "#pricing",
  },
  {
    name: "Pro",
    link: "./pro.html",
  },
  {
    name: "Blog",
    link: "./blog.html",
  },
  {
    name: "Contact",
    link: "#contact",
  },
];

export const Landing = () => {
  useEffect(() => {
    // Check if there's a hash in the URL
    const hash = window.location.hash;
    if (hash) {
      // Remove the # from the hash
      const id = hash.substring(1);
      // Find the element with the ID and scroll to it
      const element = document.getElementById(id);
      if (element) {
        // Small delay to ensure the page is fully loaded
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, []);
  const handleGetStarted = () => {
    window.location.href = ROUTES.APP_SIGNUP;
  };

  const handleWatchDemo = () => {
    // Scroll to features section
    document.querySelector("#features")?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="w-full bg-black relative">
      <FloatingNav navItems={navItems} />

      {/* Hero Section */}
      <section id="home" className="relative pt-16">
        <div className="relative z-10 container mx-auto px-4 pt-12">
          <div className="max-w-5xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-center"
            >
              <h1 className="text-4xl md:text-5xl font-light text-white mb-6">
                Text Extraction with
                <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-blue-100 to-blue-600 font-extralight mt-4 pb-4">
                  Advanced OCR Technology
                </span>
              </h1>
              <p className="text-lg text-neutral-300 max-w-xl mx-auto mb-8 font-light">
                Seamlessly Convert PDFs and Transform Images to Text in Seconds.
              </p>
              <div className="flex flex-wrap gap-4 justify-center mb-12">
                <Button
                  className="!bg-white/10 hover:!bg-white/20 backdrop-blur-sm"
                  withArrow={false}
                  onClick={handleGetStarted}
                >
                  Start Free
                </Button>
                <Button
                  className="!bg-white/10 hover:!bg-white/20 backdrop-blur-sm"
                  withArrow={false}
                  onClick={handleWatchDemo}
                >
                  Watch Demo
                </Button>
              </div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="mt-16 max-w-5xl mx-auto relative group"
              >
                <div
                  style={{
                    perspective: "1000px",
                  }}
                  className="relative rounded-2xl overflow-hidden"
                >
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 1, delay: 0 }}
                    variants={{
                      hidden: { opacity: 0, y: 0, scale: 1, rotateX: 45 },
                      visible: {
                        opacity: 1,
                        y: [0, -20, 0],
                        scale: [1, 1.2, 1],
                        rotateX: 0,
                      },
                    }}
                    className="relative rounded-2xl overflow-hidden bg-gradient-to-br from-slate-800 to-slate-800/[0.9]"
                  >
                    {/* Beam effects */}
                    <Beam className="top-0" />
                    <Beam className="top-0" />

                    <img
                      src="/images/app-preview.webp"
                      alt="OCR Application Preview"
                      className="w-full h-auto rounded-2xl"
                    />

                    {/* Bottom gradient */}
                    <div className="absolute bottom-0 right-4 mt-[2px] flex h-8 items-end overflow-hidden">
                      <div className="flex -mb-px h-[2px] w-80 -scale-x-100">
                        <div className="w-full flex-none blur-sm [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                        <div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="relative">
        <FeaturesSection />
      </section>

      {/* Languages Section */}
      <section className="py-16 px-4 relative">
        <div className="max-w-6xl mx-auto">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-3xl md:text-4xl font-light text-center text-white mb-12"
          >
            Global Language Support
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-lg text-neutral-300 text-center max-w-3xl mx-auto mb-8 font-light"
          >
            While our interface is translated into 5 languages, our advanced OCR
            technology can detect and extract text from over 100+ languages
            worldwide, making it truly global.
          </motion.p>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-8 mb-12">
            {[
              { code: "GB", name: "English", supported: true },
              { code: "DE", name: "German", supported: true },
              { code: "FR", name: "French", supported: true },
              { code: "ES", name: "Spanish", supported: true },
              { code: "RO", name: "Romanian", supported: true },
            ].map((language) => (
              <motion.div
                key={language.code}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="flex flex-col items-center gap-3"
              >
                <div className="relative w-[64px] h-[42px] rounded-lg overflow-hidden shadow-lg group">
                  <img
                    src={`/images/flags/${language.code}.png`}
                    alt={`${language.name} flag`}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                </div>
                <span className="text-white font-light">{language.name}</span>
              </motion.div>
            ))}
          </div>
          <div className="flex flex-wrap gap-4 justify-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="flex flex-wrap gap-2 items-center justify-center max-w-3xl text-neutral-400 text-xl font-light bg-white/5 rounded-lg p-4"
            >
              <span>
                <Logo
                  height="22"
                  width="auto"
                  className="text-white"
                  style={{ maxHeight: "22px" }}
                />
              </span>
              <span className="text-blue-400 font-extralight">
                enables text extraction from over 100 additional languages
              </span>
              <span className="whitespace-nowrap">
                <Button
                  className="!bg-white/10 hover:!bg-white/20 backdrop-blur-sm"
                  withArrow={false}
                  onClick={handleGetStarted}
                >
                  Start Free
                </Button>
              </span>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <PricingSection />

      {/* Contact Section */}
      <ContactSection />

      {/* Footer */}
      <footer className="mt-12 border-t border-white/10 bg-black/20 backdrop-blur-lg relative z-10">
        <div className="max-w-7xl mx-auto py-6 px-4">
          <div className="flex flex-col md:flex-row justify-between items-center md:items-start max-w-6xl mx-auto">
            <div className="flex flex-col items-center text-center w-full md:w-48 mb-8 md:mb-0">
              <Logo
                height="32"
                width="auto"
                className="text-white mb-3"
                style={{ maxHeight: "32px" }}
              />
              <p className="text-neutral-400 font-light text-sm">
                Text Extraction with
                <span className="block text-blue-400">
                  Advanced OCR Technology
                </span>
              </p>
            </div>
            <div className="flex flex-wrap justify-center gap-8 sm:gap-12 md:gap-16 lg:gap-24">
              <div className="w-full sm:w-auto px-4 mb-6 sm:mb-0">
                <h4 className="text-white font-light mb-3">Product</h4>
                <ul className="space-y-2 text-neutral-400 text-center sm:text-left">
                  <li>
                    <a
                      href="#features"
                      className="hover:text-white transition-colors"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pricing"
                      className="hover:text-white transition-colors"
                    >
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a
                      href={ROUTES.PRO}
                      className="hover:text-white transition-colors"
                    >
                      Enterprise
                    </a>
                  </li>
                  <li>
                    <a
                      href="#demo"
                      className="hover:text-white transition-colors"
                    >
                      Demo
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full sm:w-auto px-4 mb-6 sm:mb-0">
                <h4 className="text-white font-light mb-3">Company</h4>
                <ul className="space-y-2 text-neutral-400 font-light text-center sm:text-left">
                  <li>
                    <a
                      href="#about"
                      className="hover:text-white transition-colors"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="#careers"
                      className="hover:text-white transition-colors"
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a
                      href={ROUTES.CONTACT}
                      className="hover:text-white transition-colors"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full sm:w-auto px-4 mb-6 sm:mb-0">
                <h4 className="text-white font-light mb-3">Resources</h4>
                <ul className="space-y-2 text-neutral-400 font-light text-center sm:text-left">
                  <li>
                    <a
                      href="#docs"
                      className="hover:text-white transition-colors"
                    >
                      Documentation
                    </a>
                  </li>
                  <li>
                    <a
                      href={ROUTES.BLOG}
                      className="hover:text-white transition-colors"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="#support"
                      className="hover:text-white transition-colors"
                    >
                      Support
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full sm:w-auto px-4">
                <h4 className="text-white font-light mb-3">Legal</h4>
                <ul className="space-y-2 text-neutral-400 text-center sm:text-left">
                  <li>
                    <a
                      href="#privacy"
                      className="hover:text-white transition-colors"
                    >
                      Privacy
                    </a>
                  </li>
                  <li>
                    <a
                      href="#terms"
                      className="hover:text-white transition-colors"
                    >
                      Terms
                    </a>
                  </li>
                  <li>
                    <a
                      href="#cookies"
                      className="hover:text-white transition-colors"
                    >
                      Cookies
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-6 pt-6 border-t border-white/10 text-center text-neutral-400 font-light">
            <p>© 2025 extrly. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};
