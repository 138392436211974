import { type Plan } from "@/types";

export const PLANS: Plan[] = [
  {
    name: "free",
    price: 0,
    features: [
      "50 MB Storage",
      "2 Extractions per month",
      "Advanced OCR features",
      "Batch processing",
    ],
  },
  {
    name: "pro",
    price: 19.99,
    features: [
      "500 MB Storage",
      "50 Extractions per month",
      "Advanced OCR features",
      "Batch processing",
    ],
  },
  {
    name: "enterprise",
    price: 39.99,
    features: [
      "1 GB Storage",
      "100 Extractions per month",
      "Advanced OCR features",
      "Batch processing",
      "API Access",
    ],
  },
  {
    name: "custom",
    price: "Contact us",
    isCustom: true,
    features: [
      "Custom storage limits",
      "Negociated number of extractions",
      "Custom export formats",
      "API Access",
      "Custom integrations",
      "Ask for more",
    ],
  },
];
